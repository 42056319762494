import _defineProperty from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/** extend additional color here */
var extendedColors = {
  brand: {
    900: '#362066',
    800: '#452475',
    700: '#542c85',
    600: '#663399',
    500: '#7026B9',
    400: '#b17acc',
    300: '#d9bae8',
    200: '#f1defa',
    100: '#f6edfa',
    50: '#fcfaff'
  },
  magenta: {
    900: '#690147',
    800: '#7d0e59',
    700: '#940159',
    600: '#a6026a',
    500: '#bc027f',
    400: '#d459ab',
    300: '#e899ce',
    200: '#f2c4e3',
    100: '#ffe6f6',
    50: '#fffafd'
  },
  blue: {
    900: '#004ca3',
    800: '#006ac1',
    700: '#047bd3',
    600: '#0e8de6',
    500: '#0d96f2',
    400: '#3fa9f5',
    300: '#63b8f6',
    200: '#90cdf9',
    100: '#dbf0ff',
    50: '#f5fcff'
  },
  orange: {
    900: '#db3a00',
    800: '#e65800',
    700: '#f67300',
    600: '#fb8400',
    500: '#ffb238',
    400: '#ffd280',
    300: '#ffe4a1',
    200: '#ffedbf',
    100: '#fff4db',
    50: '#fffcf7'
  }
};
/** override chakra colors here */

var overridenChakraColors = {};

var colors = _objectSpread(_objectSpread({}, overridenChakraColors), extendedColors);

export default colors;